import React from 'react';
import { QueryKeys } from '../api/config';
import { DateFilter } from '../types';
import { getWorkingWeek } from '../utils/date-time-utils';

const week0 = getWorkingWeek(0);

const loads: DateFilter = {
    page: '/loads', // this should match react-router page
    mode: 'week',
    offset: 0,
    from: week0.sundayIso,
    to: week0.saturdayIso,
    includeDrafts: false,
    includedDates: [],
};

const drafts: DateFilter = {
    page: '/drafts',
    mode: 'week',
    offset: 0,
    from: week0.sundayIso,
    to: week0.saturdayIso,
    includeDrafts: true,
    includedDates: [],
};

const productionCardGroups: DateFilter = {
    page: '/production-planning',
    mode: 'week',
    offset: 0,
    from: week0.sundayIso,
    to: week0.saturdayIso,
    includeDrafts: false,
    includedDates: [],
};

const initialState = {
    companyId: 0,
    updateCompanyId: (n: number) => {},
    dateFilters: {
        [QueryKeys.loads]: loads,
        [QueryKeys.drafts]: drafts,
        [QueryKeys.productionCardGroups]: productionCardGroups,
    },
    updateDateFilters: (k: string, f: Partial<DateFilter>) => {},
};

export const AgisticsContext = React.createContext(initialState);

interface AgisticsProviderProps {
    children: React.ReactNode;
}

export const AgisticsProvider: React.FC<AgisticsProviderProps> = ({ children }) => {
    const [companyId, setCompanyId] = React.useState(initialState.companyId);
    const [dateFilters, setDateFilters] = React.useState(initialState.dateFilters);

    const updateCompanyId = (value: number) => {
        setCompanyId(value);
    }

    const updateDateFilters = (key: string, newFilter: Partial<DateFilter>) => {
        const filterToUpdate = dateFilters[key];

        const updatedFilter = {
            ...filterToUpdate,
            ...newFilter,
        }

        const updatedFilters = {
            ...dateFilters,
            [key]: {
                ...updatedFilter
            }
        }

        setDateFilters(updatedFilters);
    }

    return (
        <AgisticsContext.Provider
            value={{
                companyId,
                updateCompanyId,
                dateFilters,
                updateDateFilters,
        }}>
            {children}
        </AgisticsContext.Provider>
    )
}

// There is a difference between companyId and organizationId
// A normal users companyId will always be their organizationId, the difference is
// admin@dptl.com the companyId is the company that they are viewing at the time
export const useCompanyId = () => {
    const agisticsContext = React.useContext(AgisticsContext);

    return agisticsContext.companyId;
}

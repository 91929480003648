import { useQuery } from '@tanstack/react-query';
import { defaultQueryOptions } from '../config';
import { endOfDay, getWorkingWeek, utcWithTz } from '../../utils/date-time-utils';
import { ordersToDateTimeRange } from '../../utils/data-mapping-utils';
import { deepEqual } from '../../utils/deep-equal';
import moment from 'moment-timezone';
import { useContext } from 'react';
import { AgisticsContext } from '../../app/agistics-context';

const fetchLoadsWithGuid = async (queryKey: string, guid: string) => {
    const fakeQueryContext = {
        queryKey: [queryKey],
    }
    const loads = await defaultQueryOptions.queries.queryFn(fakeQueryContext, {
        params: { orderGroupGuid: guid }
    });
    const range = ordersToDateTimeRange(loads);
    const filter = {
        preset: 'custom',
        from: moment(range?.begin).format('YYYY-MM-DD'),
        to: moment(range?.end).format('YYYY-MM-DD')
    };

    return {
        loads: loads.map(x => ({ ...x, orderGroupGuid: x.orderGroup.guid })),
        filter
    };
};

const fetchLoadsWithDateFilter = async (queryKey:string, dateFilter) => {
    const fakeQueryContext = {
        queryKey: [queryKey],
    }
    let includedDates = dateFilter?.includedDates;
    if (includedDates && queryKey !== "/self/loads") {
        //completedAt should only be available/used on the loads page
        includedDates = includedDates.filter((x: number) => x !== 4)
    }
    const loads = await defaultQueryOptions.queries.queryFn(fakeQueryContext, {
        params: {
            startDate: utcWithTz(dateFilter.from),
            endDate: utcWithTz(endOfDay(dateFilter.to)),
            ...(includedDates && { includedDates }),
        }
    });

    return {
        loads,
        filter: dateFilter
    };
};

export const useLoadsWithDateFilter = (baseKey: string, queryKey: (string | number | number[])[]) => {
    const { dateFilters, updateDateFilters } = useContext(AgisticsContext);
    const dateFilter = dateFilters[baseKey];

    const query = useQuery(queryKey, async () => {
        let result;
        const guid = (new URLSearchParams(window.location.search)).get('orderGroupGuid');
        if (guid) {
            result = await fetchLoadsWithGuid(baseKey, guid);
        } else if (dateFilter.from && dateFilter.to) {
            result = await fetchLoadsWithDateFilter(baseKey, {
                ...dateFilter,
            });
        } else {
            const week0 = getWorkingWeek(0);
            result = await fetchLoadsWithDateFilter(baseKey, {
                preset: week0.weekNumber,
                from: week0.sundayIso,
                to: week0.saturdayIso
            });
        }

        if (!deepEqual(result.filter, dateFilter)) {
            updateDateFilters(baseKey, dateFilter);
        }

        return { data: result.loads, timeWindow: { from: result.filter.from, to: result.filter.to } };
    }, {
        staleTime: 30 * 1000,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    return { ...query, isLoading: query.isLoading };
};

import React from 'react';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { L } from 'harmony-language';
import { SearchBar } from '../search-bar';
import { DateFiltering } from './date-filtering';
import { ButtonWithTooltip } from '../inputs/button-with-tooltip';
import { FilterBar } from './load-filter-bar';
import { Load } from '../../../types';
import { LoadFiltering } from './utils/use-load-filtering';
import { LoadSearching } from './utils/use-load-searching';
import { LoadTableAction } from './load-table';
import { LoadSelection } from './utils/use-load-selection';
import { useDebounce } from '../hooks/use-debounce';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useUser } from '../../../api/queries/use-user';
import { ComparisonFilters } from './comparison-filters';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    padding: '1rem',
    flexDirection: 'column',
    gap: '5px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

interface SelectionToolbarProps {
    selectedRows: Load[];
    clearAllRowsSelected: () => void;
    actions: LoadTableAction[]
}

const SelectionToolbar: React.FC<SelectionToolbarProps> = (props) => {
    const {
        selectedRows,
        clearAllRowsSelected,
        actions
    } = props;

    const { user } = useUser();
    const selfPermissions = user?.roles?.flatMap(x => x.permissions).map(x => x?.name) || [];
    const selfFeatures = user.organization?.features?.map(x => x.name) || [];
    const rows = selectedRows;

    const filteredActions = actions
        .filter(a => a.permissions ? a.permissions.every(p => selfPermissions.includes(p)) : true)
        .filter(a => a.features ? a.features.every(p => selfFeatures.includes(p)) : true)
        .filter(a => a?.displayBool === false ? false : true);

    return (
        <Grid container justifyContent='space-between' alignItems='center' direction='row'>
            <Grid item xs={12} md={2}>
                <Typography
                    color='inherit'
                    variant='subtitle1'
                >
                    {L.xSelected(`${selectedRows.length}`)}
                </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
                <Grid container spacing={1} justifyContent='flex-end' alignItems='center'>
                    {filteredActions.map((a) => {
                        const enabled = (!a.condition || a.condition(rows));
                        const title = enabled ? a.enabledTooltipText : a.disabledTooltipText;

                        return (
                            <Grid key={a.id} item>
                                <ButtonWithTooltip
                                    tooltipText={title}
                                    disabled={!enabled}
                                    date-testid={title}
                                    onClick={() => {
                                        a.action(rows, clearAllRowsSelected);
                                    }}
                                    color='primary'
                                    variant='contained'
                                    startIcon={a.icon}>
                                    {a.label(rows)}
                                </ButtonWithTooltip>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

interface LoadTableToolbarProps {
    data: Load[];
    isLoading: boolean;
    filtering: LoadFiltering;
    searching: LoadSearching;
    selection: LoadSelection;
    actions: LoadTableAction[];
    tableKey: string;
    onlyCustomDateFilter?: boolean;
    refetch: () => void;
}

export const LoadTableToolbar: React.FC<LoadTableToolbarProps> = props => {
    const {
        data,
        filtering,
        searching,
        selection,
        actions,
        tableKey,
        isLoading,
        onlyCustomDateFilter,
        refetch,
    } = props;

    const numSelected = selection.selectedLoads?.length;
    const [search, setSearch] = React.useState('');
    const { debouncedValue } = useDebounce(search);
    React.useEffect(() => {
        searching.handleChange(debouncedValue)
    }, [searching.handleChange, debouncedValue])

    const tooltipDisplay = React.useMemo(() => (<div>
        <Typography fontSize={'0.8rem'}>{`${L.stopArrivalTime()}: YYYY-MM-DD`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.loadId()}: ${L.id()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.driver()}: ${L.name()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.tractor()}: ${L.name()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.trailer()}: ${L.name()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.cargo()}: ${L.cargoType()} - ${L.productionPlan()} - ${L.lotId()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.orderNumber()}: ${L.orderNumber()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.location()}: ${L.name()} - ${L.description()} - ${L.subLocation()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.loadingTeam()}: ${L.name()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.customerOrderNumber()}: ${L.customerOrderNumber()}`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.readyTime()}: YYYY-MM-DD`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.requiredBegin()}: YYYY-MM-DD`}</Typography>
        <Typography fontSize={'0.8rem'}>{`${L.requiredEnd()}: YYYY-MM-DD`}</Typography>
        </div>
    ), []);

    return (
        <StyledToolbar>
            {numSelected > 0 ?
                <SelectionToolbar clearAllRowsSelected={selection.clearAll} selectedRows={selection.selectedLoads}
                    actions={actions}/>
                :
                <Grid container alignItems='center' direction='row' spacing={0}>
                    <Grid item xs={12} sm={10} md={10} lg={10}>
                        <DateFiltering onlyCustom={Boolean(onlyCustomDateFilter)} tableKey={tableKey} refetch={refetch} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Grid container justifyContent='flex-end' alignItems='center'>
                            <Grid item style={{ display: 'flex' }}>
                                <Tooltip
                                    arrow
                                    placement={'left'}
                                    title={tooltipDisplay}>
                                        <InfoOutlinedIcon />
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <SearchBar
                                    placeholder={L.numRecords(`${data.length}`)}
                                    text={search}
                                    setText={setSearch}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <FilterBar data={data} filtering={filtering} tableKey={tableKey} isLoading={isLoading}/>
            <ComparisonFilters filtering={filtering} />
        </StyledToolbar>
    );
};
